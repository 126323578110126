<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="机构">
          <mechanism-select :mecid.sync="mechanismid"></mechanism-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="time"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="checktime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间">
          <el-select v-model="orderType" placeholder="请选择" clearable>
              <el-option
              v-for="item in orders"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
          </el-select>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="payList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="流水号" prop="flowing_no"></el-table-column>
        <el-table-column label="机构id" prop="mechanism_id"></el-table-column>
        <!-- <el-table-column label="机构名称">
          <template slot-scope="{row}">
            <div v-if="MecList[row.mechanism_id]">
              {{MecList[row.mechanism_id].mechanism_name}}
            </div>
            <div></div>
          </template>
        </el-table-column> -->
        <el-table-column label="金额" prop="amount"></el-table-column>
        <el-table-column
          label="下单时间"
          width="200"
          prop="finished_time"
        ></el-table-column>
        <el-table-column label="支付描述" prop="rcharge_type"></el-table-column>
        <el-table-column
          label="支付描述"
          prop="rcharge_abstract"
        ></el-table-column>
        <el-table-column label="支付状态">
          <template slot-scope="{ row }">
            <div v-if="row.finished" style="color: green">已支付</div>
            <div v-else>未支付</div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="payTotal"
    ></paging-fy>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "财务管理中心", secondNav: "机构结算" },
      mechanism_list: [],
      payList: [],
      mechanismid: null,
      is_loading: true,
      refuseReason: false,
      showReason: false,
      showDialog: false,
      user_mobile: null,
      refuse_contect: "",
      is_loading: false,
      currentPage: 1,
      payTotal: 0,
      temp: {},
      startTime: "",
      endTime: "",
      time: [],
      MecList: {},
      orders:[{
        label:'海马课包结算',
        value:'结算'
      },{
        label:'教练需求结算',
        value:'教练需求结算'
      },{
        label:'机构需求结算',
        value:'机构需求结算'
      }],
      orderType:'结算'
    };
  },
  created() {
    this.getRefoundList();
  },
  methods: {
    checktime() {
      this.startTime = this.time[0] + " 00:00:00";
      this.endTime = this.time[1] + " 23:59:59";
    },
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            mechanism_id: this.mechanismid,
            finished: true,
            rcharge_type:this.orderType,
            start_time: this.startTime || undefined,
            update_time: this.endTime || undefined,
          },
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style></style>
